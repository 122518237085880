import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import { getBase } from '@maxtropy/components';
import RootRouteBoundary from '../components/RootRouteBoundary';
import Account from '../pages/Account';
import Activation from '../pages/Activation';
import EditProfile from '../pages/EditProfile';
import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import WxBind from '../pages/WxBind';

const router = createBrowserRouter(
  [
    {
      path: '/*',
      errorElement: <RootRouteBoundary />,
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Navigate to="/manage-account" replace />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'manage-account',
          element: <Account />,
        },
        {
          path: 'activate',
          element: <Activation />,
        },
        {
          path: 'forgot-password',
          element: <ResetPassword />,
        },
        {
          path: 'edit-profile',
          element: <EditProfile />,
        },
        {
          path: 'wx-bind',
          element: <WxBind />,
        },
      ],
    },
  ],
  {
    basename: getBase(),
  }
);

export default router;
