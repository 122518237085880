// string -> arraybuffer
function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}
// arrayBuffer -> base64String
function transformUint8ArrayToBase64(arrays: ArrayBuffer) {
  const array = new Uint8Array(arrays);
  let binary = "";
  let length = array.byteLength;
  for (let i = 0; i < length; i++) {
    binary += String.fromCharCode(array[i]);
  }
  return window.btoa(binary);
}

// publicKey -> CryptoKey
function importRsaKey(publicKey: string) {
  // 将字符串通过 base64 解码为二进制数据
  const binaryDerString = window.atob(publicKey);
  // 将二进制字符串转换为 ArrayBuffer
  const binaryDer = str2ab(binaryDerString);
  return window.crypto.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: "SHA-256"
    },
    true,
    ["encrypt"]
  );
}

export function encrypt(key: string, data: string) {
  return importRsaKey(key).then(cryptoKey => {
    return window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      cryptoKey,
      str2ab(data)
    )
  }).then(encryptArrayBuffer => {
    return transformUint8ArrayToBase64(encryptArrayBuffer)
  })
}