import { CheckOutlined } from '@ant-design/icons';

import { postMessage } from '../../lib/util';

import styles from './index.module.scss';
import { FC } from 'react';
import { Button } from '@maxtropy/components';

interface SuccessPageProps {
  wxSessionCode?: string;
}

const SuccessPage: FC<SuccessPageProps> = props => {
  const { wxSessionCode } = props;

  const onClick = () => {
    window
      .fetch('/server/logout', {
        credentials: 'include',
        mode: 'no-cors',
      })
      .finally(() => {
        postMessage({ status: 'success', wxSessionCode });
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <div className={styles.icon} style={{ backgroundColor: '#12CAA1' }}>
          <CheckOutlined />
        </div>
        <div className={styles.content}>绑定成功</div>
      </div>
      <div className={styles.button}>
        <Button
          onClick={onClick}
          type="primary"
          block
          size="large"
          style={{ width: '100%' }}
          wrapStyle={{
            width: '100%',
          }}
        >
          完成
        </Button>
      </div>
    </div>
  );
};

export default SuccessPage;
