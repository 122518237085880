import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import './App.scss';

import { FC } from 'react';
import router from './router';
import { CustomApp, useThemeContext } from '@maxtropy/components';

const App: FC = () => {
  const theme = useThemeContext();
  return (
    <div className="App">
      <ConfigProvider locale={zhCN} theme={theme}>
        <CustomApp style={{ height: '100%' }}>
          <RouterProvider router={router} />
        </CustomApp>
      </ConfigProvider>
    </div>
  );
};

export default App;
