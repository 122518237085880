import {
  BusinessCodeError,
  ResponseCodeError,
  fetch,
  getBase,
  getMicroAppBaseRoute,
  locationReplace,
} from '@maxtropy/components';
import qs from 'qs';

// export enum AFSErrorCode {
//   SUCCESS_1 = "100",
//   SUCCESS_2 = "200",
//   NEED_NC = "400",
//   BLOCK_1 = "800",
//   BLOCK_2 = "900",
// }

export interface SMSTokenResponse {
  limit: number;
  // bizSuccess: boolean;
  // bizErrorCode?: AFSErrorCode;
}

export function sendSMSToken(cellphone: string): Promise<SMSTokenResponse> {
  return fetch(
    '/api/v2/selfmgmt/sendtoken',
    {
      method: 'POST',
      body: JSON.stringify({
        cellphone,
      }),
    },
    true
  );
}

export function sendLoginSMSToken(cellphone: string): Promise<SMSTokenResponse> {
  return fetch(
    '/cs/v2/login/sms',
    {
      method: 'POST',
      body: JSON.stringify({
        cellphone,
      }),
    },
    true
  );
}

export interface ActivateResetPasswordRequest {
  code: string;
  oldPassword: string;
  cellphone: string;
  newPassword: string;
}

export function activateResetPassword(request: ActivateResetPasswordRequest): Promise<void> {
  return fetch(
    '/api/v2/selfmgmt/resetpassword',
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface TokenResponse {
  token: string;
}

export function getVerifyCellphoneToken(cellphone: string, code: string): Promise<TokenResponse> {
  return fetch(
    '/api/v2/selfmgmt/verifytoken',
    {
      method: 'POST',
      body: JSON.stringify({
        code,
        cellphone,
      }),
    },
    true
  );
}

export interface forgotResetPasswordRequest {
  cellphone: string;
  newPassword: string;
  token: string;
}

export function forgotResetPassword(request: forgotResetPasswordRequest): Promise<void> {
  return fetch(
    '/api/v2/selfmgmt/forgetpassword',
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

interface WXIdentity {
  avatarUrl: string;
  city: string;
  country: string;
  gender?: string;
  language: string;
  nickname: string;
  province: string;
}

export interface User {
  muid: string;
  name: string;
  username: string;
  cellphone: string;
  expired: boolean;
  disabled: boolean;
  weixinIdentity: WXIdentity | null;
}

export function getCurrentUserInfo(): Promise<User> {
  return fetch(
    `/api/v2/selfmgmt`,
    {
      method: 'POST',
    },
    true
  );
}

export function updateCurrentUser(name: string, cellphone: string): Promise<void> {
  return fetch(
    '/api/v2/selfmgmt/update',
    {
      method: 'POST',
      body: JSON.stringify({
        name,
        cellphone,
      }),
    },
    true
  );
}

export function bindWx(appId: string, wxSessionCode: string) {
  return fetch(`/api/weixin-auth/bind?appId=${appId}&wxSessionCode=${wxSessionCode}`, {
    method: 'POST',
  });
}

// 微信解绑
export function unBindWX(): Promise<void> {
  return fetch(`/api/weixin-auth/unbind`, {
    method: 'POST',
  });
}

export async function getEncrypt() {
  const res = await window.fetch(getBase() + `/cs/v2/login/publicKey`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      timestamp: new Date().valueOf(),
    }),
  });
  if (res.status !== 200) {
    throw res;
  } else {
    return res.text();
  }
}

export interface LoginParams {
  username: string;
  password: string;
}

export async function login(text: string): Promise<void> {
  const search = window.location.search;
  const parseSearch: { service?: string; source?: string; appKey?: string } = qs.parse(search.replace('?', ''));
  const params = {
    encryptData: text,
    service: parseSearch.service,
    source: parseSearch.source,
    appKey: parseSearch.appKey,
    timestamp: new Date().valueOf(),
  };
  const ticketRes = await window.fetch(getBase() + `/cs/v2/login/encrypt`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(params),
  });
  const ticket = await ticketRes.text();

  if (!ticketRes.ok) {
    throw new ResponseCodeError(ticketRes.status, undefined, ticket, ticket);
  }
  const ticketJson = JSON.parse(ticket);
  if (ticketJson?.code !== 0) {
    throw new BusinessCodeError(ticketJson?.code, ticketJson?.msg, ticketJson);
  }
  const ticketKey = ticketJson?.data?.st;
  if (parseSearch.service && ticketKey) {
    const url = new URL(parseSearch.service);
    const parseRedirectSearch = qs.parse(url.search, { ignoreQueryPrefix: true });

    const base = getBase();
    if (!base)
      locationReplace(`${url.origin}${url.pathname}?${qs.stringify({ ...parseRedirectSearch, ticket: ticketKey })}`);
    else
      locationReplace(`${getMicroAppBaseRoute()}/ms-cc?${qs.stringify({ ...parseRedirectSearch, ticket: ticketKey })}`);
  }
}
