import { CloseOutlined } from '@ant-design/icons';

import { postMessage } from '../../lib/util';

import styles from './index.module.scss';
import { FC } from 'react';
import { Button } from '@maxtropy/components';

enum ErrorType {
  INVALID_USER = 'invalid_user',
  WEIXIN_SESSION_INVALID = 'weixin_session_invalid',
  WEIXIN_OTHER_BINDING_EXISTS = 'weixin_other_binding_exists',
}

const message: { [index: string]: string } = {
  [ErrorType.INVALID_USER]: '登录异常，请重新登录',
  [ErrorType.WEIXIN_SESSION_INVALID]: '登录异常！请稍后重试',
  [ErrorType.WEIXIN_OTHER_BINDING_EXISTS]: '当前账号已存在微信与之绑定，请输入未绑定微信的账号',
};

interface ErrorPageProps {
  errorMessage: string;
  errorType: string;
}

const ErrorPage: FC<ErrorPageProps> = props => {
  const { errorMessage, errorType } = props;
  const onClick = () => {
    if (errorType === ErrorType.WEIXIN_OTHER_BINDING_EXISTS) {
      window.location.href = `/cas/logout?service=${encodeURIComponent(window.location.href)}`;
    } else {
      window
        .fetch('/server/logout', {
          credentials: 'include',
          mode: 'no-cors',
        })
        .finally(() => {
          postMessage({ status: 'error', errorType: errorType });
        });
    }
  };

  return (
    <>
      <div className={styles.iconWrapper}>
        <div className={styles.icon} style={{ backgroundColor: '#FF3B30' }}>
          <CloseOutlined />
        </div>
        <div className={styles.content}>绑定失败</div>
        <div className={styles.desc}>{message[errorType] || errorMessage}</div>
      </div>
      <div className={styles.button}>
        <Button
          onClick={onClick}
          type="primary"
          block
          size="large"
          style={{ width: '100%' }}
          wrapStyle={{
            width: '100%',
          }}
        >
          重新绑定
        </Button>
      </div>
    </>
  );
};

export default ErrorPage;
