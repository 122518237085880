import styles from './index.module.scss';
import { Layout } from 'antd';
import { User } from '../../api';
import { FC } from 'react';

const { Header } = Layout;

interface UserInfoProps {
  userData: User | undefined;
}

const UserInfo: FC<UserInfoProps> = ({ userData }) => {
  const welcomeName = userData?.name;

  return (
    <div className={styles.userInfoContainer}>
      <div className={styles.divider} />
      <div className={styles.welcomeContent}>
        <div className={styles.defaultUserIcon} />
        <div className={styles.dropdownContainer}>
          <div className={styles.menuDropdown}>
            <div className={styles.welcomeText}>{`你好！${welcomeName}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface MenuHeaderProps extends UserInfoProps {}

const MenuHeader: FC<MenuHeaderProps> = ({ userData }) => {
  return (
    <Header className={styles.header}>
      <div className={styles.title}>客户中心</div>
      <UserInfo userData={userData} />
    </Header>
  );
};

export default MenuHeader;
