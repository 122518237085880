import { FC, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { ResponseCodeError, useSSOAuth } from '@maxtropy/components';

import { bindWx } from '../../api';
import { getQueryVariable } from '../../lib/util';

import ErrorPage from './ErrorPage';
import SuccessPage from './SuccessPage';

import styles from './index.module.scss';

const WxBind: FC = () => {
  const state = useSSOAuth(true, false, true);
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<ResponseCodeError>();

  const appId = getQueryVariable('appId');
  const wxSessionCode = getQueryVariable('wxSessionCode');

  useEffect(() => {
    if (appId && wxSessionCode) {
      bindWx(appId, wxSessionCode)
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((e: ResponseCodeError) => {
          if (e.status !== 403) {
            setError(e);
            setLoading(false);
          }
        });
    }
  }, [state, appId, wxSessionCode]);

  return (
    <div className={styles.container}>
      {loading === true ? (
        <LoadingOutlined style={{ fontSize: 35 }} />
      ) : success ? (
        <SuccessPage wxSessionCode={wxSessionCode ?? ''} />
      ) : (
        <ErrorPage errorMessage={error?.errorMessage ?? ''} errorType={error?.errorType ?? ''} />
      )}
    </div>
  );
};

export default WxBind;
