import './public-path';
import { Fragment, StrictMode } from 'react';
import { microAppRewrite, ThemeProvider, Themes } from '@maxtropy/components';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';

microAppRewrite();

const root = createRoot(document.getElementById('root') as Element);

const StrictModeComponent = process.env.NODE_ENV === 'development' ? Fragment : StrictMode;

root.render(
  <StrictModeComponent>
    <ThemeProvider theme={Themes.DARK}>
      <App />
    </ThemeProvider>
  </StrictModeComponent>
);
